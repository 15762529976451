/** @format */

import React from "react";
import { Container, Typography, Grid, Paper, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import elegant from "../../assets/images/whyUsIcons/elegant.png";
import delicious from "../../assets/images/whyUsIcons/delicious.png";
import unique from "../../assets/images/whyUsIcons/unique.png";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    textAlign: "center",
    marginTop: "2rem",
    marginBottom: "5rem",
  },
  sectionTitle: {
    marginBottom: "3rem",
    color: theme.palette.primary.dark,
  },
  paper: {
    marginBottom: "30px",
    height: "230px",
    borderRadius: "10px",
    padding: "15px",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "0px",
      marginRight: "30px",
    },
  },
  reasonImg: {
    marginBottom: "15px !important",
  },
  reasonTitle: {
    ...theme.textStyle,
    fontSize: "25px !important",
    fontWeight: "bold !important",
    marginBottom: "15px !important",
  },
  reasonDescription: {
    ...theme.textStyle,
    color: theme.palette.secondary.main.main,
  },
  textStyle: { ...theme.textStyle },
}));

export default function WhyUs() {
  const classes = useStyles();
  const reasonTitles = ["Elegant", "Delicious", "Unique"];
  const reasonImgs = [elegant, delicious, unique];
  const reasonDescriptions = [
    "Our beautiful and elegant cake designs will emphasize your classy nature, and will impress your guests",
    "Don't you love it when you can't forget about a delicious cake you ate at your friend's birthday last year? Yep, that's exactly what we're aiming for",
    "We promise you have not tried anything like our cakes and desserts before. We constantly add new and unique recipes to our menu",
  ];

  return (
    <div className={classes.wrapper} data-testid="why-us">
      <Container>
        <Grid className={classes.sectionTitle}>
          <Typography variant="h3" className={classes.textStyle}>
            WHY US
          </Typography>
        </Grid>
        <Grid container>
          {[0, 1, 2].map((value) => {
            return (
              <Grid xs={12} sm={4} item key={reasonTitles[value]}>
                <Paper className={classes.paper} elevation={3}>
                  <Box className={classes.reasonImg}>
                    <img
                      src={reasonImgs[value]}
                      width={"20%"}
                      height={"20%"}
                      alt="why us"
                    />
                  </Box>
                  <Typography className={classes.reasonTitle}>
                    {reasonTitles[value]}
                  </Typography>
                  <Typography className={classes.reasonDescription}>
                    {reasonDescriptions[value]}
                  </Typography>
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </div>
  );
}
