/** @format */

import { React, useState, useEffect } from "react";
import {
  Container,
  Typography,
  Grid,
  Box,
  Divider,
  Paper,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import moment from "moment";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: "#fd776280 ",
    marginBottom: "3rem !important",
    padding: "2rem 0 2rem 0",
  },
  wrapperForAddOns: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "space-between",
    },
  },
  orderHistoryBlockStyle: {
    borderRadius: "5px",
    backgroundColor: "#fff",
    marginBottom: "2rem !important",
    padding: "10px",
  },
  noOrderHistoryBlockStyle: {
    borderRadius: "5px",
    backgroundColor: "#fff",
    height: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  orderDetailTitle: {
    display: "inline",
    color: "#828282",
    marginRight: "10px !important",
    ...theme.textStyle,
  },
  orderDetailInfo: {
    display: "inline",
    fontWeight: "500",
    ...theme.textStyle,
  },
  orderPicStyle: {
    width: "100px",
    height: "100px",
    borderRadius: "10px",
    [theme.breakpoints.up("sm")]: {
      width: "150px",
      height: "150px",
    },
  },
  addOnTitle: {
    fontWeight: "bold",
    marginTop: "10px !important",
    marginBottom: "10px !important",
    [theme.breakpoints.up("sm")]: {
      marginTop: "0px !important",
    },
  },
  addOnDivider: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  textStyle: { ...theme.textStyle },
}));

export default function Content() {
  const classes = useStyles();
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const [orders, setOrders] = useState([]);
  const [dataIsReady, setDataIsReady] = useState(false);

  useEffect(() => {
    async function fetchUserOrders() {
      const res = await axios.get(
        "/api/orders/user?email=" + currentUser.email
      );
      setOrders(res.data);
      setDataIsReady(true);
    }

    if (currentUser) {
      fetchUserOrders();
    }
  }, []);

  return (
    <Grid className={classes.wrapper} data-testid="myorders-content">
      <Container>
        {dataIsReady && orders.length > 0 ? (
          <OrderHistory orders={orders} />
        ) : (
          <NoOrderHistory />
        )}
      </Container>
    </Grid>
  );
}

// comment just to test something out.
function NoOrderHistory() {
  const classes = useStyles();

  return (
    <Box className={classes.noOrderHistoryBlockStyle}>
      <Typography className={classes.textStyle}>No Order History</Typography>
    </Box>
  );
}

function OrderHistory(props) {
  const classes = useStyles();
  const orders = props.orders;

  return orders.map((order) => {
    return (
      <Paper
        className={classes.orderHistoryBlockStyle}
        key={order.orderId}
        elevation={10}
      >
        <Box className={classes.wrapperForAddOns}>
          <Box>
            <Box sx={{ mb: 1 }}>
              <Typography className={classes.orderDetailTitle}>
                Order #:
              </Typography>
              <Typography className={classes.orderDetailInfo}>
                {order.orderId}
              </Typography>
            </Box>

            <Box sx={{ mb: 1 }}>
              <Typography className={classes.orderDetailTitle}>
                Order was placed on:
              </Typography>
              <Typography className={classes.orderDetailInfo}>
                {dayjs(order.orderDate).format("MMM DD, YYYY")}
              </Typography>
            </Box>

            <Box sx={{ mb: 1 }}>
              <Typography className={classes.orderDetailTitle}>
                Order Total:
              </Typography>
              <Typography className={classes.orderDetailInfo}>
                {"$" + order.total.toFixed(2) + " CAD"}
              </Typography>
            </Box>

            <Box sx={{ mb: 1 }}>
              <Typography className={classes.orderDetailTitle}>
                Collection Type:
              </Typography>
              <Typography className={classes.orderDetailInfo}>
                {order.collectionType}
              </Typography>
            </Box>

            <Box sx={{ mb: 1 }}>
              <Typography className={classes.orderDetailTitle}>
                {order.collectionType === "pickup"
                  ? "Pickup Location: "
                  : "Delivery Address: "}
              </Typography>
              <Typography className={classes.orderDetailInfo}>
                {order.collectionType === "pickup" ? order.collectionLocation : ""}
              </Typography>
            </Box>

            <Box sx={{ mb: 1 }}>
              <Typography className={classes.orderDetailTitle}>
                Collection Date & Time:
              </Typography>
              <Typography className={classes.orderDetailInfo}>
                {dayjs(order.collectionDate).format("MMM DD, YYYY") +
                  " at " +
                  order.collectionTime}
              </Typography>
            </Box>
          </Box>

          {/* {order.addOns.length > 0 && (
            <Box sx={{ maxWidth: "450px" }}>
              <Divider className={classes.addOnDivider}></Divider>
              <Typography className={classes.addOnTitle}>Add Ons:</Typography>
              {order.addOns.map((addOn) => {
                return (
                  <Typography>
                    {addOn.name +
                      ": " +
                      addOn.text +
                      " ($" +
                      addOn.price.toFixed(2) +
                      ")"}
                  </Typography>
                );
              })}
            </Box>
          )} */}
        </Box>

        <Divider></Divider>
        <Box sx={{ mb: 3 }}></Box>

        {order.products.map((product, i) => {
          if (
            product.type === "cake" ||
            product.type === "standardCake" ||
            product.type === "custom-cake"
          ) {
            return (
              <Grid container sx={{ mb: 3 }}>
                <Grid item xs={4} sm={2}>
                  <img
                    src={product.imgPath}
                    className={classes.orderPicStyle}
                    alt="cake-pic"
                  />
                </Grid>
                <Grid item xs={8} sm={10}>
                  <Box sx={{ mb: 1 }}>
                    <Typography className={classes.orderDetailTitle}>
                      Name:
                    </Typography>
                    <Typography className={classes.orderDetailInfo}>
                      {product.name}
                    </Typography>
                  </Box>

                  <Box sx={{ mb: 1 }}>
                    <Typography className={classes.orderDetailTitle}>
                      Price:
                    </Typography>
                    <Typography className={classes.orderDetailInfo}>
                      {"$" + product.price.toFixed(2) + " CAD"}
                    </Typography>
                  </Box>

                  <Box sx={{ mb: 1 }}>
                    <Typography className={classes.orderDetailTitle}>
                      Size:
                    </Typography>
                    <Typography className={classes.orderDetailInfo}>
                      {product.size}
                    </Typography>
                  </Box>

                  <Box sx={{ mb: 1 }}>
                    <Typography className={classes.orderDetailTitle}>
                      Flavor:
                    </Typography>
                    <Typography className={classes.orderDetailInfo}>
                      {product.flavor}
                    </Typography>
                  </Box>

                  {product.type === "standardCake" && product.category === "Honey Cake" && <Box sx={{ mb: 1 }}>
                    <Typography className={classes.orderDetailTitle}>
                      Color:
                    </Typography>
                    <Typography className={classes.orderDetailInfo}>
                      {product.color}
                    </Typography>
                  </Box>}

                  {"customizations" in product &&
                    product.customizations.map((option) => {
                      return (
                        <Box sx={{ mb: 1 }}>
                          <Typography className={classes.orderDetailTitle}>
                            {option.parentDisplayName}:
                          </Typography>
                          <Typography className={classes.orderDetailInfo}>
                            {option.selection}
                          </Typography>
                        </Box>
                      );
                    })}

                  <Box sx={{ mb: 1 }}>
                    <Typography className={classes.orderDetailTitle}>
                      Quantity:
                    </Typography>
                    <Typography className={classes.orderDetailInfo}>
                      {product.quantity}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            );
          } else if (product.type === "assortment") {
            return (
              <Grid container sx={{ mb: 3 }}>
                <Grid item xs={4} sm={2}>
                  <img
                    src={product.imgPath}
                    className={classes.orderPicStyle}
                    alt="cake-pic"
                  />
                </Grid>
                <Grid item xs={8} sm={10}>
                  <Box sx={{ mb: 1 }}>
                    <Typography className={classes.orderDetailTitle}>
                      Name:
                    </Typography>
                    <Typography className={classes.orderDetailInfo}>
                      {product.name}
                    </Typography>
                  </Box>

                  <Box sx={{ mb: 1 }}>
                    <Typography className={classes.orderDetailTitle}>
                      Price:
                    </Typography>
                    <Typography className={classes.orderDetailInfo}>
                      {"$" + product.price.toFixed(2) + " CAD"}
                    </Typography>
                  </Box>

                  <Box sx={{ mb: 1 }}>
                    <Typography className={classes.orderDetailTitle}>
                      Quantity:
                    </Typography>
                    <Typography className={classes.orderDetailInfo}>
                      {product.quantity}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            );
          } else {
            const flavors = Object.keys(product.flavors);
            const quantity = Object.values(product.flavors);

            return (
              <Grid container sx={{ mb: 3 }}>
                <Grid item xs={4} sm={2}>
                  <img
                    src={product.imgPath}
                    className={classes.orderPicStyle}
                    alt="cake-pic"
                  />
                </Grid>
                <Grid item xs={8} sm={10}>
                  <Box sx={{ mb: 1 }}>
                    <Typography className={classes.orderDetailTitle}>
                      Name:
                    </Typography>
                    <Typography className={classes.orderDetailInfo}>
                      {product.name}
                    </Typography>
                  </Box>

                  <Box sx={{ mb: 1 }}>
                    <Typography className={classes.orderDetailTitle}>
                      Price:
                    </Typography>
                    <Typography className={classes.orderDetailInfo}>
                      {"$" + product.price.toFixed(2) + " CAD"}
                    </Typography>
                  </Box>

                  <Box sx={{ mb: 1 }}>
                    <Typography className={classes.orderDetailTitle}>
                      Size:
                    </Typography>
                    <Typography className={classes.orderDetailInfo}>
                      {product.size}
                    </Typography>
                  </Box>

                  <Grid container sx={{ mb: 1 }}>
                    <Grid item xs={4} sm={1}>
                      <Typography className={classes.orderDetailTitle}>
                        Flavors:
                      </Typography>
                    </Grid>
                    <Grid item xs={8} sm={11}>
                      {flavors.map((f, i) => {
                        return (
                          <Typography className={classes.orderDetailInfo}>
                            {f + " x" + quantity[i]}
                            <br />
                          </Typography>
                        );
                      })}
                      <Typography
                        className={classes.orderDetailInfo}
                      ></Typography>
                    </Grid>
                  </Grid>

                  <Box sx={{ mb: 1 }}>
                    <Typography className={classes.orderDetailTitle}>
                      Quantity:
                    </Typography>
                    <Typography className={classes.orderDetailInfo}>
                      {product.quantity}
                    </Typography>
                  </Box>

                  <Box sx={{ mb: 1 }}>
                    <Typography className={classes.orderDetailTitle}>
                      Packaging:
                    </Typography>
                    <Typography className={classes.orderDetailInfo}>
                      {product.packaging}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            );
          }
        })}
      </Paper>
    );
  });
}
