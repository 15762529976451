import React, { useEffect, useState } from "react";
import { Button, Typography, Box } from "@mui/material";
import { Add as AddIcon, Remove as RemoveIcon } from "@mui/icons-material";

export default function QuantitySelector({
  itemsPerSet,
  handleQuantityChange,
  setMinimumOnly = false,
  initialQuantity,
  minQTY,
}) {
  const [quantity, setQuantity] = useState(initialQuantity);

  const handleIncrement = () => {
    let newQuantity;
    if (setMinimumOnly) {
      newQuantity = quantity + 1;
    } else {
      newQuantity = quantity + itemsPerSet;
    }

    if (newQuantity >= itemsPerSet) {
      setQuantity(newQuantity);
      handleQuantityChange(newQuantity);
    }
  };

  const handleDecrement = () => {
    let newQuantity;

    if (setMinimumOnly) {
      newQuantity = quantity - 1;
    } else {
      newQuantity = quantity - itemsPerSet;
    }

    if (newQuantity >= minQTY) {
      setQuantity(newQuantity);
      handleQuantityChange(newQuantity);
    } else {
      alert(`minimum required quantity is ${minQTY}`);
    }
  };

  useEffect(() => {
    setQuantity(initialQuantity);
  }, [initialQuantity]);

  return (
    <Box
      sx={{
        display: "flex !important",
        flexDirection: "row !important",
        justifyContent: "center !important",
        alignItems: "center !important",
      }}>
      <Button
        size="small"
        sx={{
          color: "black",
          minWidth: "30px !important",
        }}
        onClick={handleDecrement}>
        <RemoveIcon />
      </Button>
      <Typography
        sx={{
          border: "1.5px solid lightgrey",
          borderRadius: "5px",
          width: "40px",
          height: "40px",
          fontSize: "1rem",
          fontWeight: "bold",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginLeft: "0 !important",
          marginRight: "0 !important",
          backgroundColor: "white",
        }}>
        {quantity}
      </Typography>
      <Button
        size="small"
        sx={{ color: "black", minWidth: "30px !important" }}
        onClick={handleIncrement}>
        <AddIcon />
      </Button>
    </Box>
  );
}
