/** @format */

import { React, useState, useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import axios from "axios";
import { Typography, Grid, TextField, Paper, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { promoCodeAmountApplied, promoCodeCancelled } from "../../redux/reducers/cart";
import SectionHeadings from "../../components/sectionHeadings";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: "2rem !important",
    marginBottom: "1rem !important",
    padding: "15px 10px",
  },
  sectionTitle: {
    color: theme.palette.secondary.dark,
    marginBottom: "20px !important",
    textAlign: "center",
  },
  sectionSubTitle: {
    color: theme.palette.secondary.main.main,
    textAlign: "center",
    marginBottom: "40px !important",
  },
  applyPromoCodeButton: {
    ...theme.containedButtonDark,
    textStyle: { ...theme.textStyle },
  },
  cancelPromoCodeButton: {
    ...theme.containedButtonLight,
    textStyle: { ...theme.textStyle },
  },
}));

function Coupon(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { products, coupon } = props;

  let totalAmountOfProducts = 0;
  let promoCodeAmount = 0;

  const [promoCodes, setPromoCodes] = useState([]);
  const [promoCode, setPromoCode] = useState("");
  const [promoCodeError, setPromoCodeError] = useState(false);
  const [promoCodeErrorText, setPromoCodeErrorText] = useState("");
  const [promoCodeApplied, setPromoCodeApplied] = useState(coupon !== null ? coupon?.applied : false);

  useEffect(() => {
    const fetchCoupons = async () => {
      const result = await axios.get("/api/app/data/coupons");
      const promoCodes = result.data;
      setPromoCodes(promoCodes);
    };

    fetchCoupons();
  }, []);

  const handleApplyBtnClick = () => {
    if (!promoCode) {
      setPromoCodeError(true);
      setPromoCodeErrorText("promo code cannot be blank");
      return;
    }

    const pCode = promoCodes.find((e) => e.id === promoCode);

    if (!pCode) {
      setPromoCodeError(true);
      setPromoCodeErrorText("promo code is not valid ");
    } else {
      if (pCode.isActive) {
        const expiryDate = new Date(pCode.expiresAt);
        if (expiryDate < Date.now()) {
          setPromoCodeError(true);
          setPromoCodeErrorText("promo code is expired");
        } else {
          setPromoCodeError(false);
          setPromoCodeApplied(true);
          applyCoupon(products, {...pCode, applied: true});
        }
      } else {
        setPromoCodeError(true);
        setPromoCodeErrorText("promo code is not active at the moment");
      }
    }
  };

  const handleCancelBtnClick = () => {
    setPromoCode("");
    setPromoCodeApplied(false);
    setPromoCodeError(false);
    setPromoCodeErrorText("");
    dispatch(promoCodeCancelled());
  }

  const applyCoupon = (products, promoCode) => {
    if(promoCode.level === "productLevel"){
      for (let i = 0; i < products.length; i++) {
        if (products[i].name === promoCode.productType) {
          totalAmountOfProducts += products[i].price * products[i].quantity;
        }
      }
    }
    else if(promoCode.level === "productCategoryLevel"){
      for (let i = 0; i < products.length; i++) {
        if (products[i].type === promoCode.productType) {
          totalAmountOfProducts += products[i].price * products[i].quantity;
        }
      }    
    }
    else if(promoCode.level === "orderLevel"){
      for (let i = 0; i < products.length; i++) {
        totalAmountOfProducts += products[i].price * products[i].quantity;
      } 
    }
    else{
      return;
    }

    if (promoCode.type === "percent") {
      promoCodeAmount = (totalAmountOfProducts * promoCode.amount) / 100;
    } else {
      promoCodeAmount = promoCode.amount;
    }

    promoCode["appliedAmount"] = promoCodeAmount.toFixed(2);
    dispatch(promoCodeAmountApplied(promoCode));
  };

  const handleCouponCodeChange = (event) => {
    setPromoCode(event.target.value);
  };

  return (
    <Paper elevation={10} className={classes.wrapper} data-testid="coupon">
      <Grid container>
        <SectionHeadings
          heading="Promo Code"
          subheading="Do you have a promo code? Enter below"
        />
        <Grid item xs={12}>
          <TextField
            fullWidth
            sx={{ mb: 2 }}
            label="Enter promo code here"
            value={promoCode}
            onChange={(e) => {
              handleCouponCodeChange(e);
            }}
            InputLabelProps={{
              className: classes.textStyle,
            }}
            InputProps={{ className: classes.textStyle }}
            data-testid="coupon-field"
          ></TextField>
          <Typography
            className={classes.textStyle}
            sx={{
              color: "red",
              mb: 2,
              display: promoCodeError ? "block" : "none",
            }}
          >
            {promoCodeErrorText}
          </Typography>
          <Typography
            className={classes.textStyle}
            sx={{
              color: "green",
              mb: 2,
              display: promoCodeApplied ? "block" : "none",
            }}
          >
            {"promo code is applied"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Button
                fullWidth 
                className={classes.applyPromoCodeButton}
                onClick={() => handleApplyBtnClick()}
                data-testid="coupon-apply"
              >
                <Typography className={classes.textStyle}>Apply</Typography>
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                sx={{display: promoCodeApplied ? "block" : "none"}}
                fullWidth
                className={classes.cancelPromoCodeButton}
                onClick={() => handleCancelBtnClick()}
              >
                <Typography className={classes.textStyle}>Cancel</Typography>
              </Button>
            </Grid>
          </Grid>
          
          
        </Grid>
      </Grid>
    </Paper>
  );
}

function mapStateToProps(state) {
  const { products, coupon } = state.cart;
  return { products, coupon };
}

export default connect(mapStateToProps)(Coupon);
