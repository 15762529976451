/** @format */

import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Typography, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import wedding from "../../assets/images/services/wedding.jpg";
import birthday from "../../assets/images/services/birthday.jpg";
import team from "../../assets/images/services/team-party.jpg";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    textAlign: "center",
    marginTop: "3rem",
    backgroundColor: theme.palette.primary.main,
  },
  service: {
    paddingBottom: "2rem",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      textAlign: "left",
      marginBottom: "3rem",
    },
  },
  sectionTitle: {
    ...theme.textStyle,
    paddingTop: "2rem",
    marginBottom: "3rem",
  },
  serviceTitle: {
    ...theme.textStyle,
    color: theme.palette.primary.dark,
    fontSize: "35px",
    fontWeight: "500",
    marginBottom: "2rem",
  },
  serviceImg: {
    marginBottom: "1rem",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  serviceImgDesktop: {
    width: "1600px",
    height: "400px",
    marginRight: "3rem",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  serviceDescription: {
    ...theme.textStyle,
    fontSize: "18px",
    marginBottom: "2rem",
    [theme.breakpoints.up("sm")]: {
      width: "80%",
    },
  },
  birthdayServiceDesktopImg: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: "3rem",
    },
  },
  actionButton: {
    ...theme.containedButtonDark,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "3rem",
    },
  },
  textStyle: { ...theme.textStyle },
}));

export default function OurServices() {
  const classes = useStyles();
  let navigate = useNavigate();

  return (
    <div className={classes.wrapper} data-testid="our-services">
      <Container>
        <div className={classes.sectionTitle}>
          <Typography
            className={classes.textStyle}
            sx={{ typography: { sm: "h3", xs: "h4" } }}
            variant="h3"
          >
            OUR SERVICES
          </Typography>
        </div>
        <div className={classes.service}>
          <div className={classes.serviceImgDesktop}>
            <img src={wedding} width={"100%"} height={"100%"} alt="" />
          </div>
          <div>
            <div className={classes.serviceTitle}>Weddings</div>
            <div className={classes.serviceImg}>
              <img src={wedding} width={"100%"} height={"100%"} alt="" />
            </div>
            <div className={classes.serviceDescription}>
              We know how stressful weddings can be. Joggling many things at
              once is very difficult and as we all know the wedding cake is the
              star of the show. That's why we want to take that burden from you
              and help you worry about one less thing while being assured that
              everybody will be speaking about the cake after the party. Contact
              us for a quote.
            </div>
            {/* <div>
              <Button
                size={"large"}
                variant="contained"
                className={classes.actionButton}
                onClick={() => navigate("/cakes/custom-cakes")}
              >
                START BUILDING
              </Button>
            </div> */}
          </div>
        </div>
        <div className={classes.service}>
          <div>
            <div className={classes.serviceTitle}>Birthdays</div>
            <div className={classes.serviceImg}>
              <img src={birthday} width={"100%"} height={"100%"} alt="" />
            </div>
            <div className={classes.serviceDescription}>
              No birthday will be complete without a cake, especially the kids
              birthdays. It will be our outmost pleasure to be trusted to make
              your or your kids birthday cake. We also offer things like
              candles, and birthday cards, to save you another trip to the
              store.
            </div>
            {/* <div>
              <Button
                size={"large"}
                variant="contained"
                className={classes.actionButton}
                onClick={() => navigate("/cakes/specialty-cakes")}
              >
                <Typography className={classes.textStyle}>
                  START BUILDING
                </Typography>
              </Button>
            </div> */}
          </div>
          <div
            className={clsx(
              classes.serviceImgDesktop,
              classes.birthdayServiceDesktopImg
            )}
          >
            <img src={birthday} width={"100%"} height={"100%"} alt="" />
          </div>
        </div>
        <div className={classes.service}>
          <div className={classes.serviceImgDesktop}>
            <img src={team} width={"100%"} height={"100%"} alt="" />
          </div>
          <div>
            <div className={classes.serviceTitle}>Corporate Events</div>
            <div className={classes.serviceImg}>
              <img src={team} width={"100%"} height={"100%"} alt="" />
            </div>
            <div className={classes.serviceDescription}>
              Whether you want to impress your loved one or you want to reward
              your team for the great job they did, we'll help you take care of
              that by providing the tastiest variety boxes where everyone will
              find something suitable to their taste.
            </div>
            {/* <div>
              <Button
                size={"large"}
                variant="contained"
                className={classes.actionButton}
                onClick={() => navigate("/assortments")}
              >
                SHOP ASSORTED BOXES
              </Button>
            </div> */}
          </div>
        </div>
      </Container>
    </div>
  );
}
