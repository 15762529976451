/** @format */

import React, {useEffect} from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ReactGA from "react-ga";
import LandingPage from "./views/LandingPage";
import Product from "./views/Product";
import MyOrders from "./views/MyOrders";
import Cart from "./views/ShoppingCart";
import Login from "./views/Login";
import Signup from "./views/Signup";
import SignupConfirmation from "./views/Signup/signUpConfirmation";
import ResetPassword from "./views/Login/resetPassword";
import Success from "./views/Payment/Success";
import Fail from "./views/Payment/Fail";
import Availability from "./views/ShoppingCart/availability";
import PrivateRouter from "./components/privateRoute";
import BookingPage from "./views/Admin/booking/main";
import CakeOrderForm from "./views/CakeOrderForm";
import ApprovalPage from "./views/CakeOrderForm/ApprovalPage";
import WeddingPackage from "./views/WeddingPackage";
import AdminHomePage from "./views/Admin";
import CorporateEvents from "./views/CorporateEvents";
import SpecialtyCakes from "./views/SpecialtyCakes";
import StandardCakes from "./views/StandardCakes";
import OrderRoot from "./views/Admin/orders";
import Cheesecake from "./views/Cheesecakes";
import AffectedUser from "./views/AffectedUsers/affectedUser";
import Patisserie from "./views/Patisserie";
import Holiday from "./views/Holiday";
import EditCollectionInfo from "./views/Admin/booking/modalContent/editOrder/EditCollectionInfo";

ReactGA.initialize("G-2KYXWZ6B3D");

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<LandingPage />} />
        <Route exact path="/update-info" element={<AffectedUser />} />

        {/* CAKES - simple change */}
        <Route
          exact
          path="/cakes/specialty-cakes"
          element={<SpecialtyCakes />}
        />
        <Route
          exact
          path="/cakes/specialty-cakes/product"
          element={<Product />}
        />
        <Route exact path="/cakes/standard-cakes" element={<StandardCakes />} />
        <Route exact path="/cakes/cheesecakes" element={<Cheesecake />} />

        {/* PATISSERIE */}
        <Route
          exact
          path="/patisserie/desserts"
          element={<Patisserie category={"dessert"} />}
        />
        <Route
          exact
          path="/patisserie/cupcakes"
          element={<Patisserie category={"cupcake"} />}
        />
        <Route
          exact
          path="/patisserie/macarons"
          element={<Patisserie category={"macaron"} />}
        />
        <Route
          exact
          path="/patisserie/cheesecakes"
          element={<Patisserie category={"cheesecake"} />}
        />
        <Route
          exact
          path="/patisserie/cookies"
          element={<Patisserie category={"cookie"} />}
        />

        {/* SERVICES */}
        <Route
          exact
          path="/services/wedding-packages"
          element={<WeddingPackage />}
        />
        <Route
          exact
          path="/services/corporate-events"
          element={<CorporateEvents />}
        />

        {/* HOLIDAY */}
        {/* <Route exact path="/holiday/christmas" element={<Holiday type={"christmas"}  />} /> */}
        {/* <Route
          exact
          path="/holiday/valentine"
          element={<Holiday type={"valentine"} />}
        /> */}

        {/* STORE ORDER FORM */}
        <Route exact path="/cakes/order-form" element={<CakeOrderForm />} />
        <Route
          exact
          path="/cakes/order-form/approval"
          element={<ApprovalPage />}
        />

        {/* ACCOUNT */}
        <Route
          exact
          path="/my-orders"
          element={
            <PrivateRouter>
              <MyOrders />
            </PrivateRouter>
          }
        />

        {/* SHOPPING CART */}
        <Route exact path="/cart" element={<Cart />} />
        <Route exact path="/schedule/availability" element={<Availability />} />

        {/* AUTHENTICATION */}
        <Route exact path="/signup" element={<Signup />} />
        <Route exact path="/signup-confirm" element={<SignupConfirmation />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/reset-password" element={<ResetPassword />} />

        {/* PAYMENT  */}
        <Route exact path="/payment/success" element={<Success />} />
        <Route exact path="/payment/fail" element={<Fail />} />

        {/* ADMIN */}
        <Route exact path="/admin" element={<AdminHomePage />} />
        <Route exact path="/admin/new-order" element={<OrderRoot />} />
        <Route exact path="/admin/booking" element={<BookingPage />} />
        <Route
          exact
          path="/admin/booking/order/edit/customerInfo"
          element={<EditCollectionInfo />}
        />
        <Route
          exact
          path="/admin/booking/order/edit/orderProductInfo"
          element={<EditCollectionInfo />}
        />
        <Route
          exact
          path="/admin/booking/order/edit/collectionInfo"
          element={<EditCollectionInfo />}
        />
      </Routes>
    </Router>
  );
}

export default App;
