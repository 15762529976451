/** @format */

import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Typography, Button, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import mainPic from "../../assets/images/main-pic.png";
import { theme } from "../../theme";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    textAlign: "center",
    marginTop: "3.2rem !important",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      textAlign: "left",
    },
  },
  headlines: {
    [theme.breakpoints.up("sm")]: {
      marginTop: "2rem !important",
    },
  },
  heading: {
    ...theme.textStyle,
    color: theme.palette.primary.dark,
  },
  heading2: {
    ...theme.textStyle,
    marginBottom: "2rem !important",
  },
  subHeading: {
    ...theme.textStyle,
    color: theme.palette.secondary.main,
    margin: "20px 0",
    [theme.breakpoints.up("sm")]: {
      width: "70%",
    },
  },
  button: {
    ...theme.containedButtonDark,
    marginBottom: "3rem !important",
    marginTop: "2rem !important",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "7rem !important",
    },
  },
  imgWrapper: {
    [theme.breakpoints.up("sm")]: {
      width: "500px",
      height: "500px",
    },
  },
  textStyle: { ...theme.textStyle },
}));

export default function Main() {
  const classes = useStyles();
  let navigate = useNavigate();

  return (
    <Container data-testid="main">
      <Box className={classes.wrapper} style={{ backgroundColor: "#fff" }}>
        <Box className={classes.headlines}>
          <Box className={classes.heading2}>
            <Typography variant="h2" className={classes.heading}>
              TASTE
            </Typography>

            <Typography variant="h2" className={classes.heading}>
              THE WORLD
            </Typography>
          </Box>

          <Typography variant="subtitle1" className={classes.subHeading}>
            Travel the world and feel every corner of the globe through our
            flavorful cakes and confections
          </Typography>

          <Button
            size="large"
            variant="contained"
            className={classes.button}
            onClick={() => navigate("/cakes/specialty-cakes")}
          >
            <Typography className={classes.textStyle}>SHOP NOW</Typography>
          </Button>
        </Box>
        <Box className={classes.imgWrapper}>
          <img src={mainPic} width="100%" height="100%" alt="cake" />
        </Box>
      </Box>
    </Container>
  );
}
