import React from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import logo from "../assets/images/logo.png";

const useStyles = makeStyles((theme) => ({
  logo: {
    display: "block",
    backgroundColor: theme.palette.primary.main,
    textAlign: "center !important",
    padding: "5px 0 !important",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

export default function Header() {
  const classes = useStyles();
  let navigate = useNavigate();

  return (
    <Box data-testid="header" className={classes.logo} onClick={() => navigate("/")}>
      <img src={logo} style={{ width: "150px", height: "100%" }} alt="logo" />
    </Box>
  );
}
