/** @format */

import { Container, Link, Grid, Typography, Box } from "@mui/material";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import React from "react";
import { makeStyles } from "@mui/styles";
import logo from "../assets/images/logo.png";
const useStyles = makeStyles((theme) => ({
  textStyle: {
    ...theme.textStyle,
  },
  logo: {
    display: "none !important",
    "&:hover": {
      cursor: "pointer",
    },
    [theme.breakpoints.up("sm")]: {
      display: "block !important",
    },
  },
  menuItem: {
    margin: "0 5% !important",

    [theme.breakpoints.up("sm")]: {
      margin: "0 1.1vw !important",
    },
    fontSize: "15px !important",
  },
}));

export default function Footer({ bgColor }) {
  const classes = useStyles();
  return (
    <Box
      sx={{
        backgroundColor: bgColor,
        padding: "25px 25px",
        paddingBottom: "10px",
      }}
      data-testid="footer"
    >
      <Container>
        <Grid
          container
          justifyContent="space-between"
          direction="row"
          alignItems="center"
          sx={{ paddingBottom: "25px" }}
        >
          <Box component="div" className={classes.logo}>
            <img
              src={logo}
              width={"150px"}
              height={"100%"}
              alt="cakeyhand logo"
              // sx={{ color: "#000" }}
            />
          </Box>
          <div>
            <Link
              href="/cakes/specialty-cakes"
              color="inherit"
              underline="none"
              className={classes.menuItem}
            >
              <span style={{ color: "#000000" }}>Shop</span>
            </Link>
            <Link
              // href="/cakes/predefined-cakes"
              color="inherit"
              underline="none"
              className={classes.menuItem}
            >
              <span style={{ color: "#000000" }}>Services</span>
            </Link>
            <Link
              // href="/gallery"
              color="inherit"
              underline="none"
              className={classes.menuItem}
            >
              <span style={{ color: "#000000" }}>Gallery</span>
            </Link>
            <Link
              // href="/macarons"
              color="inherit"
              underline="none"
              className={classes.menuItem}
            >
              <span style={{ color: "#000000" }}>Support</span>
            </Link>
          </div>
        </Grid>
        <hr style={{ border: "1px solid #000000" }} />

        <Grid item xs={12}>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Typography className={classes.textStyle} sx={{ color: "#000000" }}>
              <FacebookRoundedIcon />
            </Typography>
            <Typography className={classes.textStyle} sx={{ color: "#000000" }}>
              <InstagramIcon />
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            // margin: "2% 0",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography className={classes.textStyle} sx={{ color: "#000000" }}>
              &copy; 2023 CakeyHand Dessert Factory Inc.
            </Typography>
          </Box>
        </Grid>
      </Container>
    </Box>
  );
}
