import React from "react";
import NewTopAppBar from "../../components/newAppBar";
import Contact from "./contact";
import Footer from "../../components/footer";
import Main from "./main";
import Reviews from "./reviews";
import OurServices from "./services";
import WhyUs from "./whyUs";
import Header from "../../components/header";

const LandingPage = () => {
  return (
    <>
      <Header></Header>
      <NewTopAppBar></NewTopAppBar>
      <Main></Main>
      <OurServices></OurServices>
      <WhyUs></WhyUs>
      <Reviews></Reviews>
      <Contact></Contact>
      <Footer bgColor="#fd7762cc"></Footer>
    </>
  );
};

export default LandingPage;
