/** @format */

import React, { useState } from "react";
import axios from "axios";
import {
  Container,
  Typography,
  Grid,
  Button,
  TextField,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import PlaceIcon from "@mui/icons-material/Place";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    textAlign: "center",
    marginTop: "2rem",
    marginBottom: "5rem",
  },
  sectionTitle: {
    marginBottom: "1rem",
    color: theme.palette.primary.dark,
  },

  textStyle: { ...theme.textStyle },

  inputFieldText: {
    ...theme.textStyle,
    color: "#000",
  },

  sectionSubTitle: {
    color: theme.palette.secondary.main,
    fontSize: "25px",
    marginBottom: "3rem",
  },

  root: {
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#fd7762",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fd7762",
      },
    },
  },
  button: {
    ...theme.containedButtonDark,
    width: "100%",
    marginTop: "20px !important",
    marginBottom: "3rem !important",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: "1rem !important",
    },
  },
}));

export default function Contact() {
  const classes = useStyles();

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [messageIsSubmitted, setMessageIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  let isValid = true;

  const handleNameChange = (input) => {
    if (!input) {
      setNameError(true);
    } else {
      setNameError(false);
    }
    setName(input);
  };

  const handleMessageChange = (input) => {
    if (!input) {
      setMessageError(true);
    } else {
      setMessageError(false);
    }
    setMessage(input);
  };

  const handlePhoneNumberChange = (input) => {
    if (!input) {
      setPhoneNumberError(true);
    } else {
      setPhoneNumberError(false);
    }
    setPhoneNumber(input);
  };

  const handleEmailChange = (input) => {
    if (!input) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
    setEmail(input);
  };

  const handleSignup = () => {
    if (!name) {
      isValid = false;
      setNameError(true);
    }
    if (!/(.+)@(.+){2,}\.(.+){2,}/.test(email)) {
      isValid = false;
      setEmailError(true);
    }
    if (!phoneNumber) {
      isValid = false;
      setPhoneNumberError(true);
    }
    if (!message) {
      isValid = false;
      setMessageError(true);
    }

    if (isValid) {
      setLoading(true);
      setSuccess(true);
      setTimeout(async () => {
        try {
          await axios.post("/api/contact", {
            name,
            message,
            email,
            phoneNumber,
          });
          setMessageIsSubmitted(true);
          setLoading(false);
          setSuccess(false);
          setName("");
          setMessage("");
          setEmail("");
          setPhoneNumber("");
        } catch (error) {
          setLoading(false);
        }
      }, 1500);
    }
  };

  return (
    <div className={classes.wrapper} data-testid="contact">
      <Container>
        <Grid xs={12} className={classes.sectionTitle}>
          <Typography variant="h3" className={classes.textStyle}>
            CONTACT US
          </Typography>
        </Grid>
        <Grid xs={12} className={classes.sectionSubTitle}>
          <Typography className={classes.textStyle}>
            If you have any questions and would like to get in touch with us,
            please use the form below
          </Typography>
        </Grid>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} sm={5}>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": {
                  width: "100%",
                  boxShadow: 3,
                },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                required={true}
                error={nameError}
                fullWidth
                id="outlined-required"
                label="Name"
                value={name}
                margin="normal"
                helperText={nameError ? "Enter your name" : ""}
                className={classes.root}
                InputLabelProps={{
                  className: classes.inputFieldText,
                }}
                InputProps={{ className: classes.textStyle }}
                FormHelperTextProps={{ className: classes.textStyle }}
                onChange={(e) => handleNameChange(e.target.value)}
                data-testid="name"
              />
              <TextField
                required={true}
                error={phoneNumberError}
                fullWidth
                type="number"
                id="outlined-required"
                label="Phone Number"
                value={phoneNumber}
                margin="normal"
                helperText={emailError ? "Enter your phone number" : ""}
                className={classes.root}
                InputLabelProps={{
                  className: classes.inputFieldText,
                }}
                InputProps={{ className: classes.textStyle }}
                FormHelperTextProps={{ className: classes.textStyle }}
                onChange={(e) => handlePhoneNumberChange(e.target.value)}
                data-testid="phone-number"
              />
              <TextField
                required={true}
                error={emailError}
                fullWidth
                id="outlined-required"
                label="Email"
                value={email}
                margin="normal"
                helperText={emailError ? "Enter your email" : ""}
                className={classes.root}
                InputLabelProps={{
                  className: classes.inputFieldText,
                }}
                InputProps={{ className: classes.textStyle }}
                FormHelperTextProps={{ className: classes.textStyle }}
                onChange={(e) => handleEmailChange(e.target.value)}
                data-testid="email"
              />
              <TextField
                required={true}
                error={messageError}
                fullWidth
                multiline
                minRows={4}
                id="outlined-required"
                label="Message"
                value={message}
                margin="normal"
                helperText={messageError ? "Enter your message" : ""}
                className={classes.root}
                InputLabelProps={{
                  className: classes.inputFieldText,
                }}
                InputProps={{ className: classes.textStyle }}
                FormHelperTextProps={{ className: classes.textStyle }}
                onChange={(e) => handleMessageChange(e.target.value)}
                data-testid="message"
              />
              <Box sx={{ position: "relative" }}>
                <Button
                  size={"large"}
                  variant="contained"
                  className={classes.button}
                  onClick={handleSignup}
                  disabled={success}
                  data-testid="send-button"
                >
                  <Typography className={classes.textStyle}>SEND</Typography>
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-3px",
                      marginLeft: "45px",
                      color: "#fff",
                    }}
                  />
                )}
              </Box>
              {messageIsSubmitted && (
                <Typography
                  className={classes.textStyle}
                  sx={{ color: "green", mt: 2, mb: 2 }}
                >
                  Success! We've received your message.
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Box>
              <Box sx={{ display: "flex", mt: 2, mb: 4 }}>
                <PhoneIcon sx={{ mr: 3, color: "#fd7762", fontSize: "30px" }} />
                <Typography className={classes.textStyle}>
                  709 781 6341
                </Typography>
              </Box>
              <Box sx={{ display: "flex", mt: 1, mb: 4 }}>
                <EmailIcon sx={{ mr: 3, color: "#fd7762", fontSize: "30px" }} />
                <Typography className={classes.textStyle}>
                  contact@cakeyhand.com
                </Typography>
              </Box>
              <Box sx={{ display: "flex", mt: 1, mb: 4 }}>
                <PlaceIcon sx={{ mr: 3, color: "#fd7762", fontSize: "30px" }} />
                <Box>
                <Typography className={classes.textStyle}>
                  1483 Topsail rd., Paradise, NL
                </Typography>
                <Typography className={classes.textStyle}>
                  16 Stavanger Dr., St. John's, NL
                </Typography>
                </Box>
              </Box>
              <Box sx={{ display: "flex", mt: 1, mb: 3 }}>
                <FacebookIcon
                  sx={{ mr: 3, color: "#fd7762", fontSize: "30px" }}
                />
                <Typography className={classes.textStyle}>
                  Follow us on Facebook
                </Typography>
              </Box>
              <Box sx={{ display: "flex", mt: 1 }}>
                <InstagramIcon
                  sx={{ mr: 3, color: "#fd7762", fontSize: "30px" }}
                />
                <Typography className={classes.textStyle}>
                  Follow us on Instagram
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
